@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html {
    background-color: rgb(33 33 33);
}

body {
    margin: 0;
    overflow-x: hidden;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
    background-color: #212121;
}

.article {
    width: calc(100%);
    position: relative;
}

.jacques-francois-shadow-regular {
  font-family: "Jacques Francois Shadow", serif;
  font-weight: 400;
  font-style: normal;
}


.newsreader-regular {
  font-family: "Newsreader", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.newsreader-bold {
  font-family: "Newsreader", serif;
  font-optical-sizing: auto;
  font-weight: 666;
  font-style: normal;
}

.alumni-sans-regular {
  font-family: "Alumni Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.alumni-sans-bold {
  font-family: "Alumni Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}

.buttonStyle = {
  backgroundColor: '#fff';
  color: 'red';
  padding: '10px 20px';
  margin: '5px';
  border: 'none';
  borderBottom: '2px solid #000';
  cursor: 'pointer';
  fontSize: '1rem';
  fontWeight: 'bold';
  textTransform: 'uppercase';
  display: 'inline-block';
  textDecoration: 'none';
  borderRadius: '0';
};

.signin-button {
  cursor: pointer;
}

.signin-wrapper {
  position: relative;
  z-index: 1000;
}
